<template>
  <div>
    <el-form ref="form" :inline="true" :model="form" @submit.native.prevent>
      <el-form-item>
        时间
        <el-tooltip
          class="item"
          effect="dark"
          content="默认为前30天的数据，不包括今日"
          placement="top"
        >
          <i class="el-icon-warning"></i>
        </el-tooltip>
        <el-date-picker
          v-model="beginTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
          unlink-panels
          @change="pickerClick"
          style="width: 350px"
        ></el-date-picker>
        <!-- <el-input v-model="form.creatDate" placeholder="请输入标题" /> -->
      </el-form-item>
      <el-form-item label="客户">
        <el-select
          v-model="form.customer"
          multiple
          filterable
          collapse-tags
          @change="handleBlur"
          clearable
          placeholder="请选择"
          style="width: 180px"
        >
          <el-option
            v-for="item in optionList.costomerGetValidList"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="业务员">
        <el-select
          v-model="form.salesman"
          collapse-tags
          multiple
          filterable
          @change="handleBlur"
          clearable
          placeholder="请选择"
          style="width: 180px"
        >
          <el-option
            v-for="item in optionList.salesmanGetValidList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="排序列名">
        <el-select
          v-model="form.sortName"
          filterable
          style="width: 180px"
          @change="handleBlur"
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in optionListOne"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="排序方式">
        <el-select
          v-model="form.sortRule"
          filterable
          @change="handleBlur"
          placeholder="请选择"
          style="width: 100px"
        >
          <el-option
            v-for="item in optionListTwo"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div style="height: calc(100vh - 300px)">
    <el-table
      ref="tableSort"
      v-loading="listLoading"
      border
      height="100%"
      :data="list"
      element-loading-text="拼命加载中"
      @selection-change="setSelectRows"
      header-cell-class-name="table-header"
      class="table eleChange"
    >
      <el-table-column
        align="left"
        prop="customer"
        label="客户"
        show-overflow-tooltip
      />
      <el-table-column
        align="left"
        prop="inquiryNum"
        label="询价量"
        show-overflow-tooltip
      />
      <el-table-column
        align="left"
        prop="dealNum"
        label="已成交询价量"
        show-overflow-tooltip
      />
      <el-table-column
        align="left"
        prop="notDealNum"
        label="未成交询价量"
        show-overflow-tooltip
      />
      <el-table-column
        align="left"
        prop="dealPercent"
        label="询价成交率"
        show-overflow-tooltip
      />
      <el-table-column
        align="left"
        prop="quotationPriceSum"
        label="报价总金额"
        show-overflow-tooltip
      />
      <el-table-column
        align="left"
        prop="quotationPriceAvg"
        label="报价票均金额"
        show-overflow-tooltip
      />
      <el-table-column
        align="left"
        prop="dealPriceSum"
        label="成交总金额"
        show-overflow-tooltip
      />
      <el-table-column align="left" prop="dealPriceAvg" label="成交票均金额" />

      <el-table-column label="头部线路" align="center">
        <el-table-column prop="line" align="center" label="头部线路" width="250px">
          <template slot="header">
              头部线路
              <el-tooltip
                effect="dark"
                content="散货询价量前5的线路"
                placement="top"
              >
                <i class="el-icon-warning"></i>
              </el-tooltip>
            </template>
          <template slot-scope="scope">
            <div
              v-for="(item, index) in scope.row.line"
              :key="index"
              style="text-align: center"
            >
              {{ item.line }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="line" align="center" label="询价量">
          <template slot-scope="scope">
            <div
              v-for="(item, index) in scope.row.line"
              :key="index"
              style="text-align: center"
            >
              {{ item.inquiryNum }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="line" align="center" label="已成交询价量">
          <template slot-scope="scope">
            <div
              v-for="(item, index) in scope.row.line"
              :key="index"
              style="text-align: center"
            >
              {{ item.dealNum }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="line" align="center" label="成交询价率">
          <template slot-scope="scope">
            <div
              v-for="(item, index) in scope.row.line"
              :key="index"
              style="text-align: center"
            >
              {{ item.dealPercent }}
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <!-- <el-table-column
        align="center"
        prop="dealCustomerList"
        label="头部成交客户"
      /> -->
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    </div>
    <el-pagination
      background
      :current-page="form.pageNum"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>
<script>
import { businessGetCustomerInquiryList } from '@/api/businessReport'
import * as localFilter from "@/utils/dataTime"
export default {
  props: {
    optionList: Object,
  },
  data() {
    return {
      listLoading: false,
      height: this.$baseTableHeight(3) - 30,
      list: [],
      form: {
        sortName: 'inquiryNum',
        sortRule: '2',
        beginDate: '',
        endDate: '',
        customer: [],
        salesman: [],
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      layout: 'total, sizes, prev, pager, next, jumper',
      optionListOne: [
        {
          id: 'inquiryNum',
          name: '询价量',
        },
        {
          id: 'dealNum',
          name: '已成交询价量',
        },
        {
          id: 'notDealNum',
          name: '未成交询价量',
        },
        {
          id: 'dealPercent',
          name: '询价成交率',
        },
        {
          id: 'quotationPriceSum',
          name: '报价总金额',
        },
        {
          id: 'dealPriceSum',
          name: '成交总金额',
        },
      ],
      optionListTwo: [
        {
          id: '1',
          name: '升序',
        },
        {
          id: '2',
          name: '降序',
        },
      ],
      beginTime: [],
      pickerOptions: {},
    }
  },
  mounted() {
    this.beginTime.push(localFilter.getDay(-30),localFilter.getDay(-1))
    this.form.beginDate=localFilter.getDay(-30)
    this.form.endDate=localFilter.getDay(-1)
    // this.queryList()
  },
  beforeMount() {
    window.addEventListener('resize', this.handleHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleHeight)
  },
  methods: {
    handleHeight() {
      this.height = this.$baseTableHeight(3) - 30
    },
    handleSizeChange(val) {
      this.form.pageNum = 1
      this.form.pageSize = val
      this.queryList()
    },
    handleCurrentChange(val) {
      this.form.pageNum = val
      this.queryList()
    },
    pickerClick() {
      this.form.beginDate = this.beginTime ? this.beginTime[0] : ''
      this.form.endDate = this.beginTime ? this.beginTime[1] : ''
      this.queryList()
    },
    setSelectRows(val) {
      this.selectRows = val
    },
    handleBlur() {
      this.queryList()
    },
    async queryList() {
      this.listLoading = true
      const res = await businessGetCustomerInquiryList(this.form)
      this.total = res.data.totalSize
      this.list = res.data.pageList
      this.listLoading = false
    },
  },
}
</script>