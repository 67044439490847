var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { inline: true, model: _vm.form },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "el-form-item",
            [
              _vm._v(" 时间 "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "默认为前30天的数据，不包括今日",
                    placement: "top"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              ),
              _c("el-date-picker", {
                staticStyle: { width: "350px" },
                attrs: {
                  type: "daterange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "value-format": "yyyy-MM-dd",
                  "picker-options": _vm.pickerOptions,
                  "unlink-panels": ""
                },
                on: { change: _vm.pickerClick },
                model: {
                  value: _vm.beginTime,
                  callback: function($$v) {
                    _vm.beginTime = $$v
                  },
                  expression: "beginTime"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客户" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "180px" },
                  attrs: {
                    multiple: "",
                    filterable: "",
                    "collapse-tags": "",
                    clearable: "",
                    placeholder: "请选择"
                  },
                  on: { change: _vm.handleBlur },
                  model: {
                    value: _vm.form.customer,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "customer", $$v)
                    },
                    expression: "form.customer"
                  }
                },
                _vm._l(_vm.optionList.costomerGetValidList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.name }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "业务员" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "180px" },
                  attrs: {
                    "collapse-tags": "",
                    multiple: "",
                    filterable: "",
                    clearable: "",
                    placeholder: "请选择"
                  },
                  on: { change: _vm.handleBlur },
                  model: {
                    value: _vm.form.salesman,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "salesman", $$v)
                    },
                    expression: "form.salesman"
                  }
                },
                _vm._l(_vm.optionList.salesmanGetValidList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "排序列名" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "180px" },
                  attrs: {
                    filterable: "",
                    clearable: "",
                    placeholder: "请选择"
                  },
                  on: { change: _vm.handleBlur },
                  model: {
                    value: _vm.form.sortName,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "sortName", $$v)
                    },
                    expression: "form.sortName"
                  }
                },
                _vm._l(_vm.optionListOne, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "排序方式" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100px" },
                  attrs: { filterable: "", placeholder: "请选择" },
                  on: { change: _vm.handleBlur },
                  model: {
                    value: _vm.form.sortRule,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "sortRule", $$v)
                    },
                    expression: "form.sortRule"
                  }
                },
                _vm._l(_vm.optionListTwo, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 300px)" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              ref: "tableSort",
              staticClass: "table eleChange",
              attrs: {
                border: "",
                height: "100%",
                data: _vm.list,
                "element-loading-text": "拼命加载中",
                "header-cell-class-name": "table-header"
              },
              on: { "selection-change": _vm.setSelectRows },
              scopedSlots: _vm._u([
                {
                  key: "empty",
                  fn: function() {
                    return [
                      _c("el-image", {
                        staticClass: "vab-data-empty",
                        attrs: {
                          src: require("@/assets/empty_images/data_empty.png")
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "left",
                  prop: "customer",
                  label: "客户",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "left",
                  prop: "inquiryNum",
                  label: "询价量",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "left",
                  prop: "dealNum",
                  label: "已成交询价量",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "left",
                  prop: "notDealNum",
                  label: "未成交询价量",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "left",
                  prop: "dealPercent",
                  label: "询价成交率",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "left",
                  prop: "quotationPriceSum",
                  label: "报价总金额",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "left",
                  prop: "quotationPriceAvg",
                  label: "报价票均金额",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "left",
                  prop: "dealPriceSum",
                  label: "成交总金额",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "left",
                  prop: "dealPriceAvg",
                  label: "成交票均金额"
                }
              }),
              _c(
                "el-table-column",
                { attrs: { label: "头部线路", align: "center" } },
                [
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "line",
                        align: "center",
                        label: "头部线路",
                        width: "250px"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return _vm._l(scope.row.line, function(
                              item,
                              index
                            ) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticStyle: { "text-align": "center" }
                                },
                                [_vm._v(" " + _vm._s(item.line) + " ")]
                              )
                            })
                          }
                        }
                      ])
                    },
                    [
                      _c(
                        "template",
                        { slot: "header" },
                        [
                          _vm._v(" 头部线路 "),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                effect: "dark",
                                content: "散货询价量前5的线路",
                                placement: "top"
                              }
                            },
                            [_c("i", { staticClass: "el-icon-warning" })]
                          )
                        ],
                        1
                      )
                    ],
                    2
                  ),
                  _c("el-table-column", {
                    attrs: { prop: "line", align: "center", label: "询价量" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return _vm._l(scope.row.line, function(item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticStyle: { "text-align": "center" }
                              },
                              [_vm._v(" " + _vm._s(item.inquiryNum) + " ")]
                            )
                          })
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "line",
                      align: "center",
                      label: "已成交询价量"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return _vm._l(scope.row.line, function(item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticStyle: { "text-align": "center" }
                              },
                              [_vm._v(" " + _vm._s(item.dealNum) + " ")]
                            )
                          })
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "line",
                      align: "center",
                      label: "成交询价率"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return _vm._l(scope.row.line, function(item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticStyle: { "text-align": "center" }
                              },
                              [_vm._v(" " + _vm._s(item.dealPercent) + " ")]
                            )
                          })
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.form.pageNum,
          layout: _vm.layout,
          "page-size": _vm.form.pageSize,
          total: _vm.total
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }